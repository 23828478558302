// Ce JS a pour but de reprendre l'ensemble des extension au javascript.

Object.assign(String.prototype, {
  cleanFilename() {
    return this.trim().replace(/[/\\?%*:|"<>]/g, '-');
  }
});

Object.assign(String.prototype, {
  plural(qte, plural) {
    if(qte > 1) return plural; else return this.valueOf();
  }
});

// retourne un indice de luminosité d'une string de couleur #rrbbgg
Object.assign(String.prototype, {
  getLuma() {
    var c = this.substring(1);      // strip #
    var rgb = parseInt(c, 16);               // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;              // extract red
    var g = (rgb >>  8) & 0xff;              // extract green
    var b = (rgb >>  0) & 0xff;              // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; 
    return luma;
  }
})


function hasJsonStructure(str) {
  if (typeof str !== 'string') return false;
  try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]' 
          || type === '[object Array]';
  } catch (err) {
      return false;
  }
}